// Generated by BUCKLESCRIPT VERSION 3.0.0, PLEASE EDIT WITH CARE

import * as Css from "bs-css/src/Css.js";
import * as React from "react";
import * as ReasonReact from "reason-react/src/ReasonReact.js";

var styles = Css.style(/* :: */[
      Css.border(Css.px(1), Css.solid, /* `rgba */[
            -878128972,
            /* tuple */[
              0,
              0,
              0,
              0.2
            ]
          ]),
      /* :: */[
        Css.borderRadius(Css.px(2)),
        /* :: */[
          Css.padding(Css.px(4)),
          /* :: */[
            Css.boxShadow(/* None */0, /* Some */[Css.px(2)], /* Some */[Css.px(4)], /* None */0, /* Some */[true], /* `rgba */[
                  -878128972,
                  /* tuple */[
                    0,
                    0,
                    0,
                    0.08
                  ]
                ]),
            /* [] */0
          ]
        ]
      ]
    ]);

var component = ReasonReact.statelessComponent("Input");

function make(value, onChange, placeholder, _) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function () {
              return React.createElement("input", {
                          className: styles,
                          placeholder: placeholder,
                          value: value,
                          onChange: onChange
                        });
            }),
          /* initialState */component[/* initialState */10],
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */component[/* reducer */12],
          /* subscriptions */component[/* subscriptions */13],
          /* jsElementWrapped */component[/* jsElementWrapped */14]
        ];
}

export {
  styles ,
  component ,
  make ,
  
}
/* styles Not a pure module */
