// Generated by BUCKLESCRIPT VERSION 3.0.0, PLEASE EDIT WITH CARE

import * as Css from "bs-css/src/Css.js";
import * as React from "react";
import * as ReasonReact from "reason-react/src/ReasonReact.js";
import * as Button$ReactTemplate from "./Button.bs.js";
import * as SvgBoard$ReactTemplate from "./SvgBoard.bs.js";
import * as ConfirmAction$ReactTemplate from "./ConfirmAction.bs.js";

var board = Css.style(/* :: */[
      Css.margin2(Css.px(10), Css.px(0)),
      /* [] */0
    ]);

var Styles = /* module */[
  /* size */25,
  /* board */board
];

var component = ReasonReact.statelessComponent("BoardPreview");

function make(board$1, onDelete, onClickCell, _) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function () {
              return React.createElement("div", undefined, React.createElement("header", undefined, React.createElement("h3", undefined, board$1[/* name */0]), React.createElement("div", undefined, "Started on: ", React.createElement("time", undefined, board$1[/* dateStarted */3].toLocaleDateString()))), React.createElement("div", {
                              className: board
                            }, ReasonReact.element(/* None */0, /* None */0, SvgBoard$ReactTemplate.make(board$1, onClickCell, /* array */[]))), ReasonReact.element(/* None */0, /* None */0, ConfirmAction$ReactTemplate.make(onDelete, "Are you sure you want to delete this?", (function (toggle) {
                                    return ReasonReact.element(/* None */0, /* None */0, Button$ReactTemplate.make(/* Some */[/* Danger */2], /* None */0, /* Some */[toggle], /* None */0, "Delete"));
                                  }))));
            }),
          /* initialState */component[/* initialState */10],
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */component[/* reducer */12],
          /* subscriptions */component[/* subscriptions */13],
          /* jsElementWrapped */component[/* jsElementWrapped */14]
        ];
}

export {
  Styles ,
  component ,
  make ,
  
}
/* board Not a pure module */
