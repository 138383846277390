// Generated by BUCKLESCRIPT VERSION 3.0.0, PLEASE EDIT WITH CARE

import * as Css from "bs-css/src/Css.js";
import * as React from "react";
import * as ReasonReact from "reason-react/src/ReasonReact.js";
import * as Colors$ReactTemplate from "../Colors.bs.js";

function styles(buttonType) {
  var background;
  switch (buttonType) {
    case 0 : 
        background = Colors$ReactTemplate.rgb(Colors$ReactTemplate.blue);
        break;
    case 1 : 
        background = /* `hex */[
          5194459,
          "#f2f2f2"
        ];
        break;
    case 2 : 
        background = Colors$ReactTemplate.rgb(Colors$ReactTemplate.red);
        break;
    
  }
  var textColor = buttonType !== 1 ? Css.white : Css.black;
  return Css.style(/* :: */[
              Css.padding2(Css.px(4), Css.px(15)),
              /* :: */[
                Css.color(textColor),
                /* :: */[
                  Css.backgroundColor(background),
                  /* :: */[
                    Css.borderColor(background),
                    /* :: */[
                      Css.borderRadius(Css.px(4)),
                      /* :: */[
                        Css.fontSize(Css.px(14)),
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ]
            ]);
}

var component = ReasonReact.statelessComponent("Button");

function make($staropt$star, className, onClick, htmlType, children) {
  var buttonType = $staropt$star ? $staropt$star[0] : /* Default */1;
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function () {
              var style = className ? className[0] + (" " + styles(buttonType)) : styles(buttonType);
              var tmp = {
                className: style
              };
              if (htmlType) {
                tmp.type = htmlType[0];
              }
              if (onClick) {
                tmp.onClick = onClick[0];
              }
              return React.createElement("button", tmp, children);
            }),
          /* initialState */component[/* initialState */10],
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */component[/* reducer */12],
          /* subscriptions */component[/* subscriptions */13],
          /* jsElementWrapped */component[/* jsElementWrapped */14]
        ];
}

export {
  styles ,
  component ,
  make ,
  
}
/* component Not a pure module */
