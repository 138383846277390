// Generated by BUCKLESCRIPT VERSION 3.0.0, PLEASE EDIT WITH CARE


function rgb(color) {
  return /* `rgb */[
          5692173,
          /* tuple */[
            color[/* r */0],
            color[/* g */1],
            color[/* b */2]
          ]
        ];
}

function rgba(color, alpha) {
  return /* `rbga */[
          -878376502,
          /* tuple */[
            color[/* r */0],
            color[/* g */1],
            color[/* b */2],
            alpha
          ]
        ];
}

var red = /* record */[
  /* r */245,
  /* g */34,
  /* b */45
];

var blue = /* record */[
  /* r */24,
  /* g */144,
  /* b */255
];

export {
  red ,
  blue ,
  rgb ,
  rgba ,
  
}
/* No side effect */
