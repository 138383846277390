// Generated by BUCKLESCRIPT VERSION 3.0.0, PLEASE EDIT WITH CARE

import * as Js_exn from "bs-platform/lib/es6/js_exn.js";
import * as Roughjs from "roughjs";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Caml_array from "bs-platform/lib/es6/caml_array.js";
import * as Caml_int32 from "bs-platform/lib/es6/caml_int32.js";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.bs.js";

function getPos(index, padding, cellSize) {
  if (index !== 0) {
    return cellSize * index + padding * index;
  } else {
    return 0;
  }
}

function makePaths(padding, cellSize, size) {
  var generator = Roughjs.default.svg().generator;
  return Belt_Array.range(0, Caml_int32.imul(size, size) - 1 | 0).map((function (i) {
                var x = Caml_int32.mod_(i, size);
                var y = Caml_int32.div(i, size);
                var xPos = getPos(x, padding, cellSize);
                var yPos = getPos(y, padding, cellSize);
                var cell = generator.toPaths(generator.rectangle(xPos, yPos, cellSize, cellSize, {
                          fill: "transparent",
                          fillStyle: "hachure"
                        }));
                var paths_000 = /* box */Caml_array.caml_array_get(cell, 1);
                var paths_001 = /* fill */Caml_array.caml_array_get(cell, 0);
                var paths_002 = /* mark : array */[
                  Caml_array.caml_array_get(generator.toPaths(generator.line(xPos, yPos, xPos + cellSize, yPos + cellSize, { })), 0),
                  Caml_array.caml_array_get(generator.toPaths(generator.line(xPos + cellSize, yPos, xPos, yPos + cellSize, { })), 0)
                ];
                var paths = /* record */[
                  paths_000,
                  paths_001,
                  paths_002
                ];
                return /* tuple */[
                        /* Empty */0,
                        paths
                      ];
              }));
}

function make(name, size, $staropt$star, _) {
  var cellSize = $staropt$star ? $staropt$star[0] : 30;
  var padding = cellSize / 4;
  return /* record */[
          /* name */name,
          /* size */size,
          /* paths */makePaths(padding, cellSize, size),
          /* dateStarted */new Date(),
          /* padding */padding,
          /* cellSize */cellSize
        ];
}

function status(v) {
  if (v) {
    return 1;
  } else {
    return 0;
  }
}

function path(r) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "d",
                r.d
              ],
              /* :: */[
                /* tuple */[
                  "stroke",
                  r.stroke
                ],
                /* :: */[
                  /* tuple */[
                    "strokeWidth",
                    r.strokeWidth
                  ],
                  /* :: */[
                    /* tuple */[
                      "fill",
                      r.fill
                    ],
                    /* [] */0
                  ]
                ]
              ]
            ]);
}

function sketches(r) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "box",
                path(r[/* box */0])
              ],
              /* :: */[
                /* tuple */[
                  "fill",
                  path(r[/* fill */1])
                ],
                /* :: */[
                  /* tuple */[
                    "mark",
                    Json_encode.array(path, r[/* mark */2])
                  ],
                  /* [] */0
                ]
              ]
            ]);
}

function board(r) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "name",
                r[/* name */0]
              ],
              /* :: */[
                /* tuple */[
                  "size",
                  r[/* size */1]
                ],
                /* :: */[
                  /* tuple */[
                    "paths",
                    Json_encode.array((function (param) {
                            return Json_encode.pair(status, sketches, param);
                          }), r[/* paths */2])
                  ],
                  /* :: */[
                    /* tuple */[
                      "dateStarted",
                      Json_encode.date(r[/* dateStarted */3])
                    ],
                    /* :: */[
                      /* tuple */[
                        "cellSize",
                        r[/* cellSize */5]
                      ],
                      /* :: */[
                        /* tuple */[
                          "padding",
                          r[/* padding */4]
                        ],
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ]
            ]);
}

var Encode = /* module */[
  /* status */status,
  /* path */path,
  /* sketches */sketches,
  /* board */board
];

function status$1(v) {
  var match = Json_decode.$$int(v);
  if (match !== 0) {
    if (match !== 1) {
      return Js_exn.raiseError("Unexpected value while decoding board status");
    } else {
      return /* Success */1;
    }
  } else {
    return /* Empty */0;
  }
}

function path$1(json) {
  return {
          d: Json_decode.field("d", Json_decode.string, json),
          fill: Json_decode.field("fill", Json_decode.string, json),
          stroke: Json_decode.field("stroke", Json_decode.string, json),
          strokeWidth: Json_decode.field("strokeWidth", Json_decode.$$float, json)
        };
}

function sketches$1(json) {
  return /* record */[
          /* box */Json_decode.field("box", path$1, json),
          /* fill */Json_decode.field("fill", path$1, json),
          /* mark */Json_decode.field("mark", (function (param) {
                  return Json_decode.array(path$1, param);
                }), json)
        ];
}

function board$1(json) {
  return /* record */[
          /* name */Json_decode.field("name", Json_decode.string, json),
          /* size */Json_decode.field("size", Json_decode.$$int, json),
          /* paths */Json_decode.field("paths", (function (param) {
                  return Json_decode.array((function (param) {
                                return Json_decode.pair(status$1, sketches$1, param);
                              }), param);
                }), json),
          /* dateStarted */Json_decode.field("dateStarted", Json_decode.date, json),
          /* padding */Json_decode.field("padding", Json_decode.$$float, json),
          /* cellSize */Json_decode.field("cellSize", Json_decode.$$float, json)
        ];
}

var Decode = /* module */[
  /* status */status$1,
  /* path */path$1,
  /* sketches */sketches$1,
  /* board */board$1
];

export {
  getPos ,
  makePaths ,
  make ,
  Encode ,
  Decode ,
  
}
/* roughjs Not a pure module */
