// Generated by BUCKLESCRIPT VERSION 3.0.0, PLEASE EDIT WITH CARE

import * as Css from "bs-css/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as ReForm from "bs-reform/re/ReForm.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as ReasonReact from "reason-react/src/ReasonReact.js";
import * as ReForm_Helpers from "bs-reform/re/ReForm_Helpers.js";
import * as Input$ReactTemplate from "./Input.bs.js";
import * as Button$ReactTemplate from "./Button.bs.js";

var form = Css.style(/* :: */[
      Css.display(Css.flexBox),
      /* :: */[
        Css.alignItems(Css.center),
        /* [] */0
      ]
    ]);

var errorText = Css.style(/* :: */[
      Css.color(Css.red),
      /* [] */0
    ]);

var buttonStyle = Css.style(/* :: */[
      Css.marginLeft(Css.px(10)),
      /* [] */0
    ]);

var Styles = /* module */[
  /* form */form,
  /* errorText */errorText,
  /* buttonStyle */buttonStyle
];

var lens_000 = /* tuple */[
  /* name */-922783157,
  (function (s) {
      return s[/* name */0];
    }),
  (function (_, name) {
      return /* record */[/* name */name];
    })
];

var lens = /* :: */[
  lens_000,
  /* [] */0
];

var FormConfig = /* module */[/* lens */lens];

var Form = ReForm.Create(FormConfig);

var component = ReasonReact.statelessComponent("CreateBoardForm");

function make(onSubmit, _) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function () {
              return ReasonReact.element(/* None */0, /* None */0, Curry._7(Form[/* make */2], onSubmit, /* None */0, /* None */0, /* record */[/* name */""], /* :: */[
                              /* tuple */[
                                /* name */-922783157,
                                /* Required */0
                              ],
                              /* [] */0
                            ], /* None */0, (function (param) {
                                var handleSubmit = param[/* handleSubmit */3];
                                var partial_arg = Curry._1(param[/* handleChange */1], /* name */-922783157);
                                var __x = Curry._1(param[/* getErrorForField */4], /* name */-922783157);
                                return React.createElement("div", undefined, React.createElement("form", {
                                                className: form,
                                                onSubmit: (function (param) {
                                                    return ReForm_Helpers.handleDomFormSubmit(handleSubmit, param);
                                                  })
                                              }, ReasonReact.element(/* None */0, /* None */0, Input$ReactTemplate.make(param[/* form */0][/* values */0][/* name */0], (function (param) {
                                                          return ReForm_Helpers.handleDomFormChange(partial_arg, param);
                                                        }), "New Board Name", /* array */[])), React.createElement("small", {
                                                    className: errorText
                                                  }, Belt_Option.getWithDefault(__x, "")), ReasonReact.element(/* None */0, /* None */0, Button$ReactTemplate.make(/* Some */[/* Primary */0], /* Some */[buttonStyle], /* None */0, /* Some */["submit"], "Submit"))));
                              })));
            }),
          /* initialState */component[/* initialState */10],
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */component[/* reducer */12],
          /* subscriptions */component[/* subscriptions */13],
          /* jsElementWrapped */component[/* jsElementWrapped */14]
        ];
}

export {
  Styles ,
  FormConfig ,
  Form ,
  component ,
  make ,
  
}
/* form Not a pure module */
