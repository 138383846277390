// Generated by BUCKLESCRIPT VERSION 3.0.0, PLEASE EDIT WITH CARE

import * as Json from "@glennsl/bs-json/src/Json.bs.js";
import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";

function string(x) {
  return "" + (String(x) + "");
}

function append(x, xs) {
  return xs.concat(/* array */[x]);
}

function remove(index, xs) {
  return xs.slice(0, index).concat(xs.slice(index + 1 | 0));
}

function update(index, f, xs) {
  return xs.map((function (x, i) {
                var match = i === index;
                if (match) {
                  return Curry._1(f, x);
                } else {
                  return x;
                }
              }));
}

var $$Array = /* module */[
  /* append */append,
  /* remove */remove,
  /* update */update
];

function addDays(days, date) {
  var newDate = new Date(date.getTime());
  newDate.setDate(date.getDate() + days);
  return newDate;
}

var $$Date$1 = /* module */[/* addDays */addDays];

function getLocalState(key, decoder, defaultState) {
  var match = localStorage.getItem(key);
  if (match !== null) {
    return Curry._1(decoder, Json.parseOrRaise(match));
  } else {
    return defaultState;
  }
}

function updateAndSaveLocally(key, encoder, update) {
  return /* UpdateWithSideEffects */Block.__(2, [
            update,
            (function (param) {
                localStorage.setItem(key, Json.stringify(Curry._1(encoder, param[/* state */1])));
                return /* () */0;
              })
          ]);
}

function noop() {
  return /* () */0;
}

var React = /* module */[
  /* getLocalState */getLocalState,
  /* updateAndSaveLocally */updateAndSaveLocally,
  /* noop */noop
];

export {
  string ,
  $$Array ,
  $$Date$1 as $$Date,
  React ,
  
}
/* No side effect */
