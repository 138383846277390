// Generated by BUCKLESCRIPT VERSION 3.0.0, PLEASE EDIT WITH CARE

import * as Css from "bs-css/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as ReasonReact from "reason-react/src/ReasonReact.js";
import * as Util$ReactTemplate from "../lib/Util.bs.js";

var board = Css.style(/* :: */[
      Css.cursor(/* pointer */-786317123),
      /* [] */0
    ]);

var Styles = /* module */[/* board */board];

var dayInMs = 86390 * 1000;

var component = ReasonReact.reducerComponent("SvgBoard");

function make(board$1, onClick, _) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */(function (param) {
              var oldSelf = param[/* oldSelf */0];
              var shouldUpdate = oldSelf[/* state */1][/* prevBoard */0][0] !== board$1;
              oldSelf[/* state */1][/* prevBoard */0][0] = board$1;
              return shouldUpdate;
            }),
          /* render */(function () {
              var todayIndex = [0];
              var today = new Date();
              var size = board$1[/* size */1];
              var fullSize = size * board$1[/* cellSize */5] + (size - 1) * board$1[/* padding */4];
              var sizeStr = "" + (String(fullSize) + "px");
              var viewBox = Util$ReactTemplate.string(-1) + (" " + (Util$ReactTemplate.string(-1) + (" " + (Util$ReactTemplate.string(fullSize + 2) + (" " + Util$ReactTemplate.string(fullSize + 2))))));
              var paths = board$1[/* paths */2].map((function (param, i) {
                      var match = param[1];
                      var fill = match[/* fill */1];
                      var box = match[/* box */0];
                      var status = param[0];
                      var date = Util$ReactTemplate.$$Date[/* addDays */0](i, board$1[/* dateStarted */3]);
                      var dateString = date.toLocaleDateString();
                      var isToday = dateString === today.toLocaleDateString();
                      if (isToday) {
                        todayIndex[0] = i;
                      }
                      var beforeToday = today.getTime() - date.getTime() > dayInMs;
                      var match$1 = beforeToday && status === /* Empty */0;
                      var fillColor = match$1 ? "#f5222d" : "transparent";
                      return React.createElement("g", {
                                  key: Util$ReactTemplate.string(i)
                                }, React.createElement("path", {
                                      d: box.d,
                                      fill: box.fill,
                                      stroke: isToday ? "blue" : box.stroke,
                                      strokeWidth: Util$ReactTemplate.string(box.strokeWidth)
                                    }), React.createElement("path", {
                                      d: fill.d,
                                      fill: fillColor,
                                      stroke: fillColor,
                                      strokeWidth: Util$ReactTemplate.string(fill.strokeWidth)
                                    }), match[/* mark */2].map((function (path, i) {
                                        var match = status === /* Success */1;
                                        return React.createElement("path", {
                                                    key: Util$ReactTemplate.string(i),
                                                    d: path.d,
                                                    fill: path.fill,
                                                    stroke: match ? "green" : "transparent",
                                                    strokeWidth: Util$ReactTemplate.string(path.strokeWidth)
                                                  });
                                      })));
                    }));
              return React.createElement("svg", {
                          className: board,
                          height: sizeStr,
                          width: sizeStr,
                          onClick: Curry._1(onClick, todayIndex[0]),
                          viewBox: viewBox
                        }, paths);
            }),
          /* initialState */(function () {
              return /* record */[/* prevBoard */[board$1]];
            }),
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */(function (_, _$1) {
              return /* NoUpdate */0;
            }),
          /* subscriptions */component[/* subscriptions */13],
          /* jsElementWrapped */component[/* jsElementWrapped */14]
        ];
}

export {
  Styles ,
  dayInMs ,
  component ,
  make ,
  
}
/* board Not a pure module */
