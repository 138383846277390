// Generated by BUCKLESCRIPT VERSION 3.0.0, PLEASE EDIT WITH CARE

import * as Css from "bs-css/src/Css.js";
import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as ReasonReact from "reason-react/src/ReasonReact.js";
import * as Button$ReactTemplate from "./Button.bs.js";

var marginRight = Css.style(/* :: */[
      Css.marginRight(Css.px(5)),
      /* [] */0
    ]);

var marginTop = Css.style(/* :: */[
      Css.marginTop(Css.px(5)),
      /* [] */0
    ]);

var component = ReasonReact.reducerComponent("ConfirmActionButton");

function make(onClick, message, children) {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (self) {
              var toggle = function () {
                return Curry._1(self[/* send */3], /* ToggleConfirm */0);
              };
              var children$1 = Curry._1(children, toggle);
              var match = !self[/* state */1][/* showConfirmation */0];
              return React.createElement("div", undefined, match ? children$1 : React.createElement("div", undefined, React.createElement("div", undefined, message), React.createElement("div", {
                                    className: marginTop
                                  }, ReasonReact.element(/* None */0, /* None */0, Button$ReactTemplate.make(/* Some */[/* Danger */2], /* Some */[marginRight], /* Some */[onClick], /* None */0, "Yes")), ReasonReact.element(/* None */0, /* None */0, Button$ReactTemplate.make(/* None */0, /* None */0, /* Some */[toggle], /* None */0, "No")))));
            }),
          /* initialState */(function () {
              return /* record */[/* showConfirmation */false];
            }),
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */(function (_, state) {
              return /* Update */Block.__(0, [/* record */[/* showConfirmation */!state[/* showConfirmation */0]]]);
            }),
          /* subscriptions */component[/* subscriptions */13],
          /* jsElementWrapped */component[/* jsElementWrapped */14]
        ];
}

export {
  marginRight ,
  marginTop ,
  component ,
  make ,
  
}
/* marginRight Not a pure module */
