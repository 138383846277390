// Generated by BUCKLESCRIPT VERSION 3.0.0, PLEASE EDIT WITH CARE

import * as Css from "bs-css/src/Css.js";
import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Json_decode from "@glennsl/bs-json/src/Json_decode.bs.js";
import * as Json_encode from "@glennsl/bs-json/src/Json_encode.bs.js";
import * as ReasonReact from "reason-react/src/ReasonReact.js";
import * as Util$ReactTemplate from "../lib/Util.bs.js";
import * as Board$ReactTemplate from "../lib/Board.bs.js";
import * as BoardPreview$ReactTemplate from "./BoardPreview.bs.js";
import * as CreateBoardForm$ReactTemplate from "./CreateBoardForm.bs.js";

var page = Css.style(/* :: */[
      Css.height(Css.vh(100)),
      /* :: */[
        Css.display(Css.flexBox),
        /* :: */[
          Css.flexDirection(Css.column),
          /* [] */0
        ]
      ]
    ]);

var header = Css.style(/* :: */[
      Css.backgroundColor(/* `hex */[
            5194459,
            "f2f2f2"
          ]),
      /* :: */[
        Css.height(Css.px(50)),
        /* :: */[
          Css.boxShadow(/* None */0, /* Some */[Css.px(2)], /* Some */[Css.px(3)], /* None */0, /* None */0, /* `hex */[
                5194459,
                "ccc"
              ]),
          /* :: */[
            Css.display(Css.flexBox),
            /* :: */[
              Css.alignItems(Css.center),
              /* :: */[
                Css.flexShrink(0),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var headerContainer = Css.style(/* :: */[
      Css.maxWidth(Css.px(1170)),
      /* :: */[
        Css.display(Css.flexBox),
        /* :: */[
          Css.justifyContent(Css.center),
          /* :: */[
            Css.alignItems(Css.center),
            /* :: */[
              Css.marginLeft(Css.px(20)),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var content = Css.style(/* :: */[
      Css.display(Css.flexBox),
      /* :: */[
        Css.flexDirection(Css.column),
        /* :: */[
          Css.alignItems(Css.center),
          /* :: */[
            Css.flexGrow(1),
            /* :: */[
              Css.maxWidth(Css.px(1170)),
              /* :: */[
                Css.margin2(Css.px(20), Css.auto),
                /* :: */[
                  Css.overflowY(Css.auto),
                  /* :: */[
                    Css.overflowX(Css.hidden),
                    /* :: */[
                      Css.width(Css.pct(100)),
                      /* :: */[
                        Css.padding(Css.px(15)),
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var boardContainer = Css.style(/* :: */[
      Css.display(Css.flexBox),
      /* :: */[
        Css.flexWrap(Css.wrap),
        /* :: */[
          Css.justifyContent(Css.center),
          /* :: */[
            Css.marginRight(Css.px(-45)),
            /* [] */0
          ]
        ]
      ]
    ]);

var board = Css.style(/* :: */[
      Css.marginTop(Css.px(20)),
      /* :: */[
        Css.marginRight(Css.px(45)),
        /* [] */0
      ]
    ]);

var footer = Css.style(/* :: */[
      Css.height(Css.px(40)),
      /* :: */[
        Css.padding2(Css.px(0), Css.px(10)),
        /* :: */[
          Css.display(Css.flexBox),
          /* :: */[
            Css.alignItems(Css.center),
            /* :: */[
              Css.backgroundColor(/* `hex */[
                    5194459,
                    "f2f2f2"
                  ]),
              /* :: */[
                Css.flexShrink(0),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var Styles = /* module */[
  /* pageWidth */1170,
  /* page */page,
  /* header */header,
  /* headerContainer */headerContainer,
  /* content */content,
  /* boardContainer */boardContainer,
  /* board */board,
  /* footer */footer
];

var logo = (require("../logo.svg"));

function encodeState(s) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "boards",
                Json_encode.array(Board$ReactTemplate.Encode[/* board */3], s[/* boards */0])
              ],
              /* [] */0
            ]);
}

function decodeState(s) {
  var partial_arg = Board$ReactTemplate.Decode[/* board */3];
  return /* record */[/* boards */Json_decode.field("boards", (function (param) {
                  return Json_decode.array(partial_arg, param);
                }), s)];
}

var component = ReasonReact.reducerComponent("App");

function make() {
  return /* record */[
          /* debugName */component[/* debugName */0],
          /* reactClassInternal */component[/* reactClassInternal */1],
          /* handedOffState */component[/* handedOffState */2],
          /* willReceiveProps */component[/* willReceiveProps */3],
          /* didMount */component[/* didMount */4],
          /* didUpdate */component[/* didUpdate */5],
          /* willUnmount */component[/* willUnmount */6],
          /* willUpdate */component[/* willUpdate */7],
          /* shouldUpdate */component[/* shouldUpdate */8],
          /* render */(function (self) {
              return React.createElement("div", {
                          className: page
                        }, React.createElement("header", {
                              className: header
                            }, React.createElement("div", {
                                  className: headerContainer
                                }, React.createElement("img", {
                                      src: logo,
                                      width: "35px"
                                    }))), React.createElement("main", {
                              className: content
                            }, ReasonReact.element(/* None */0, /* None */0, CreateBoardForm$ReactTemplate.make((function (form) {
                                        Curry._1(self[/* send */3], /* AddBoard */Block.__(0, [Board$ReactTemplate.make(form[/* values */0][/* name */0], 7, /* None */0, /* () */0)]));
                                        return Curry._1(form[/* resetFormState */3], /* () */0);
                                      }), /* array */[])), React.createElement("div", {
                                  className: boardContainer
                                }, self[/* state */1][/* boards */0].map((function (board$1, i) {
                                        return React.createElement("div", {
                                                    key: Util$ReactTemplate.string(board$1[/* dateStarted */3].getTime()),
                                                    className: board
                                                  }, ReasonReact.element(/* None */0, /* None */0, BoardPreview$ReactTemplate.make(board$1, (function () {
                                                              return Curry._1(self[/* send */3], /* DeleteBoard */Block.__(1, [i]));
                                                            }), (function (cellIndex, _) {
                                                              return Curry._1(self[/* send */3], /* ToggleCell */Block.__(2, [
                                                                            i,
                                                                            cellIndex
                                                                          ]));
                                                            }), /* array */[])));
                                      })))), React.createElement("footer", {
                              className: footer
                            }, React.createElement("a", {
                                  href: "https://www.aria.ai"
                                }, "Made by Aria Fallah")));
            }),
          /* initialState */(function () {
              return Util$ReactTemplate.React[/* getLocalState */0]("state", decodeState, /* record */[/* boards : array */[]]);
            }),
          /* retainedProps */component[/* retainedProps */11],
          /* reducer */(function (action, state) {
              switch (action.tag | 0) {
                case 0 : 
                    return Util$ReactTemplate.React[/* updateAndSaveLocally */1]("state", encodeState, /* record */[/* boards */Util$ReactTemplate.$$Array[/* append */0](action[0], state[/* boards */0])]);
                case 1 : 
                    return Util$ReactTemplate.React[/* updateAndSaveLocally */1]("state", encodeState, /* record */[/* boards */Util$ReactTemplate.$$Array[/* remove */1](action[0], state[/* boards */0])]);
                case 2 : 
                    var cellIndex = action[1];
                    return Util$ReactTemplate.React[/* updateAndSaveLocally */1]("state", encodeState, /* record */[/* boards */Util$ReactTemplate.$$Array[/* update */2](action[0], (function (board) {
                                        return /* record */[
                                                /* name */board[/* name */0],
                                                /* size */board[/* size */1],
                                                /* paths */Util$ReactTemplate.$$Array[/* update */2](cellIndex, (function (param) {
                                                        return /* tuple */[
                                                                param[0] ? /* Empty */0 : /* Success */1,
                                                                param[1]
                                                              ];
                                                      }), board[/* paths */2]),
                                                /* dateStarted */board[/* dateStarted */3],
                                                /* padding */board[/* padding */4],
                                                /* cellSize */board[/* cellSize */5]
                                              ];
                                      }), state[/* boards */0])]);
                
              }
            }),
          /* subscriptions */component[/* subscriptions */13],
          /* jsElementWrapped */component[/* jsElementWrapped */14]
        ];
}

export {
  Styles ,
  logo ,
  encodeState ,
  decodeState ,
  component ,
  make ,
  
}
/* page Not a pure module */
